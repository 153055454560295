import { useEffect, useState } from "react";
import { Chart } from 'chart.js';
import { getBalanceHistory } from "../../../../utils/requestApi/wallet";
import { useParams } from "react-router-dom";
import moment from "moment";
import { object_reverse } from "../../../../utils/helper";


const BalanceHistory = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [days, setDays] = useState();

  useEffect(() => {
    const getWalletHistoryBalance = async () => {
      const response = await getBalanceHistory({ id, days });

      const data_all = {};

      response.data.forEach((element) => {
        let obj = {};
        const date = moment(element.date).format('MMM D');
        obj[date] = parseFloat(parseFloat(element.value).toFixed(2));
        // data_all.push(obj);
        Object.assign(data_all, obj);
        // labels.push(moment(element.date).format('MMM D'));
        // values.push(element.value);
      });

      // const data = {
      //   labels: labels.reverse(),
      //   datasets: [
      //     {
      //       label: 'Balance (USD)',
      //       data: values.reverse(),
      //       borderColor: '#359cfb',
      //       backgroundColor: '#0B4A38'
      //     }
      //   ]
      // };
      setData(object_reverse(data_all));
    };
    getWalletHistoryBalance();
  }, [days]);

  useEffect(() => {
    if (data) {
      const data_month = data

      var moveToFunction = CanvasRenderingContext2D.prototype.moveTo;
      CanvasRenderingContext2D.prototype.moveToLocation = {};
      // Переопределение метода moveTo
      CanvasRenderingContext2D.prototype.moveTo = function (x, y) {
        this.moveToLocation.x = x;
        this.moveToLocation.y = y;
        moveToFunction.apply(this, [x, y]);
      };
      CanvasRenderingContext2D.prototype.dashedLineTo = function (x, y, dashedLength) {
        dashedLength = dashedLength === undefined ? 5 : dashedLength;
        var startX = this.moveToLocation.x;
        var startY = this.moveToLocation.y;
        var deltaX = x - startX;
        var deltaY = y - startY;
        var numberDash = Math.floor(Math.sqrt(deltaX * deltaX + deltaY * deltaY) / dashedLength);
        for (var i = 0; i < numberDash; i++) {
          this[i % 2 === 0 ? 'moveTo' : 'lineTo'](startX + (deltaX / numberDash) * i, startY + (deltaY / numberDash) * i); // эквивалентно this.moveTo (x, y) или this.LineTo (x, y)
        }
        this.moveTo(x, y); // Когда пунктирная линия рисуется непрерывно, начальная точка начинается с текущей точки
      };

      CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        this.beginPath();
        this.moveTo(x + r, y);
        this.arcTo(x + w, y, x + w, y + h, r);
        this.arcTo(x + w, y + h, x, y + h, r);
        this.arcTo(x, y + h, x, y, r);
        this.arcTo(x, y, x + w, y, r);
        this.closePath();
        return this;
      }

      Number.prototype.customFixed = function (fixed) {
        var number = this;
        number = String(number);
        var split = number.split('.');
        if (split.length > 1) {
          var left = split[0];
          var right = split[1].substr(0, (!fixed ? 4 : fixed));
          return Number(left + (fixed !== 0 ? '.' + right : ''));
        } else {
          return Number(number);
        }
      }
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const canvas = document.getElementById('analyticsChartLine')
      const ctx = canvas.getContext('2d');

      //Dashed line on hover 
      const customLegend = {
        id: 'customLegend',
        beforeDatasetsDraw(chart, args, pluginOptions) {
          const { ctx, data, legend: { legendItems }, chartArea: { top, bottom }, scales: { x, y }, tooltip: { _active } } = chart
          ctx.save()
          if (_active[0]) {
            ctx.beginPath()
            ctx.lineWidth = 1
            ctx.strokeStyle = '#fff'
            ctx.moveTo(x.getPixelForValue(_active[0].index), top)
            ctx.dashedLineTo(x.getPixelForValue(_active[0].index), bottom, 2)
            ctx.stroke()
          }
        }
      }

      // first day of month position line
      function getFirstDayId(arr) {
        const result = []
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (parseInt(element.match(/\d+/)) === 1) {
            result.push(index)
          }
        }
        return result
      }

      const firstDay = {
        id: 'firstDay',
        beforeDatasetsDraw(chart, args, pluginOptions) {
          const { ctx, data, chartArea: { top, bottom }, scales: { x, y }, tooltip: { _active } } = chart
          ctx.save()
          const first_days = getFirstDayId(data.labels)
          ctx.beginPath()
          ctx.lineWidth = 1
          ctx.strokeStyle = 'rgba(255, 255, 255, 0.4)'
          first_days.forEach((element) => {
            ctx.moveTo(x.getPixelForValue(element), top)
            ctx.lineTo(x.getPixelForValue(element), bottom)
            ctx.stroke()
          });
        }
      }

      //X and Y axes labels settings
      const customScales = {
        id: 'customScales',
        afterDatasetsDraw(chart, args, pluginOptions) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, tooltip: { _active } } = chart
          ctx.save()

          // X axes
          x.ticks.forEach((tick, index) => {
            ctx.font = '600 13px Space Mono'
            ctx.fillStyle = '#99999B'
            ctx.textAlign = 'center'
            ctx.fillText(tick.label.split(' ')[0], x.getPixelForValue(tick.value), bottom + 25)
            if (tick.label.split(' ').length > 1) {
              ctx.fillText(tick.label.split(' ')[1], x.getPixelForValue(tick.value), bottom + 41)
            }
          })

          if (_active[0]) {
            ctx.font = '600 13px Space Mono'
            ctx.fillStyle = '#fff'
            ctx.textAlign = 'center'
            ctx.fillText(x.ticks[_active[0].index].label.split(' ')[0], x.getPixelForValue(_active[0].index), bottom + 25)
            if (x.ticks[_active[0].index].label.split(' ').length > 1) {
              ctx.fillText(x.ticks[_active[0].index].label.split(' ')[1], x.getPixelForValue(_active[0].index), bottom + 41)
            }
          }

          // Y axes
          y.ticks.forEach((tick, index) => {
            ctx.font = '600 13px Space Mono'
            ctx.fillStyle = '#99999B'
            ctx.textAlign = 'end'
            ctx.fillText(tick.label, left - 19, y.getPixelForValue(tick.value) + 5)
            ctx.fillText(tick.label, left - 19, y.getPixelForValue(tick.value) + 5)
            ctx.beginPath();
            ctx.fillStyle = '#fff'
            ctx.arc(left - 9, y.getPixelForValue(tick.value), 2, 0, 2 * Math.PI)
            ctx.fill()
          })
        }
      }

      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      //Custom tooltip
      const customTooltip = {
        id: 'customTooltip',
        afterDraw(chart) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, tooltip: { _active } } = chart
          // x, y, w, h, r

          if (_active[0]) {

            let xPosCorrect = 0

            if(_active[0].index === 0){
              xPosCorrect += 110
            }
            if(_active[0].index === 1){
              xPosCorrect += 65
            }
            if(_active[0].index === 2){
              xPosCorrect += 30
            }

            if(_active[0].index === 6 && days === '7'){
              xPosCorrect -= 100
            }

            if(_active[0].index === 27 ){
              xPosCorrect -= 35
            }
            if(_active[0].index === 28 ){
              xPosCorrect -= 65
            }
            if(_active[0].index > 28 ){
              xPosCorrect -= 100
            }

            ctx.beginPath()
            ctx.fillStyle = 'rgba(77, 78, 88, 0.2)'
            ctx.roundRect(x.getPixelForValue(_active[0].index) - 215 / 2 + xPosCorrect, _active[0].element.y - 48 - 19, 225, 48, 8).fill()
            // StackBlur.canvasRGB(canvas, x.getPixelForValue(_active[0].index)-215/2+24, _active[0].element.y-48-19, 225, 48, 33)
            ctx.fill()
            ctx.font = '400 14px Space Mono'
            ctx.textAlign = 'start'
            ctx.fillStyle = '#fff'
            ctx.fillText('Balance:', x.getPixelForValue(_active[0].index) - 215 / 2 + 24 + xPosCorrect, _active[0].element.y - 48)
            ctx.fillText('Change:', x.getPixelForValue(_active[0].index) - 215 / 2 + 141 + xPosCorrect, _active[0].element.y - 48)
            ctx.font = '700 14px Space Mono'
            ctx.fillStyle = '#359cfb'
            ctx.fillText('$' + numberWithCommas(data.datasets[0].data[_active[0].index]) , x.getPixelForValue(_active[0].index) - 215 / 2 + 21 + xPosCorrect, _active[0].element.y - 28)

            
            

            let prevSum = data.datasets[0].data[_active[0].index - 1]
            let curSum = data.datasets[0].data[_active[0].index]

            if (_active[0].index > 0 && typeof (prevSum) === 'number' && false) {
              let change = Math.round((curSum / prevSum * 100 - 100) * 100) / 100
              if (change > 0) {
                ctx.fillText(`+${change}%`, x.getPixelForValue(_active[0].index) - 215 / 2 + 141, _active[0].element.y - 28)
              } else {
                ctx.fillStyle = '#DB8989'
                ctx.fillText(`${change}%`, x.getPixelForValue(_active[0].index) - 215 / 2 + 141, _active[0].element.y - 28)
              }
            } else {
              ctx.fillText(`+0.00%`, x.getPixelForValue(_active[0].index) - 215 / 2 + 141 + xPosCorrect, _active[0].element.y - 28)
            }
            // ctx.fillText('+7.5%', left, top)
          }
        }
      }

      const analyticsChartLine = new Chart(ctx, {
        type: 'line',
        data: {
          labels: Object.keys(data_month),
          datasets: [{
            data: Object.values(data_month),
            backgroundColor: (context) => {
              const chart = context.chart
              const { ctx, chartArea, scales } = chart
              if (!chartArea) {
                return null
              }
              return bgGradient(ctx, chartArea, scales)
            },
            borderColor: [
              '#359cfb'
            ],
            borderWidth: 5,
            fill: true,
            pointRadius: 5,
            pointHoverRadius: 9,
            pointHoverBackgroundColor: '#359cfb',
            pointBackgroundColor: '#001728',
            pointBorderWidth: 2,
          }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {
              display: false,
              labels: Object.values(data_month),
            },
          },
          scales: {
            x: {
              ticks: {
                display: true,
                color: 'transparent',
                padding: 11,
              },
              grid: {
                color: 'rgba(81, 81, 86, 0.15)',
                borderDash: [2],
                borderDashOffset: 2,
                drawBorder: false
              },

            },
            y: {
              grace: '150%',
              ticks: {
                color: "transparent",
                padding: 10,
                callback: function (values, ticks, labels) {
                  let value = `${values}`


                  if (value.length > 3 && value.length < 7 && Number(value) > 0) {
                    return Math.abs(parseInt(value.substring(0, value.length - 3))) + 'K'
                  } else if (value.length > 6 && value.length < 10 && Number(value) > 0) {
                    return Math.abs(parseInt(value.substring(0, value.length - 6))) + "M"
                  } else if (value.length > 9){
                    return Math.abs(parseInt(value.substring(0, value.length - 9))) + "B"
                  }
                  else {
                    return value
                    //  Math.abs(value)
                  }

                  // switch (value.length) {
                  //   case :
                      
                  //     break;
                  
                  //   default: return value
                  //     break;
                  // }


                  return value.toString()
                  // value
                  // value.substr(0, value.length - 6) + 'M'
                }
              },
              grid: {
                color: 'rgba(81, 81, 86, 0.4)',
                borderDash: [2],
                borderDashOffset: 2
              },

            }
          },
        },
        plugins: [customLegend, firstDay, customScales, customTooltip],

      });

      function bgGradient(ctx, chartArea, scales) {
        const { left, right, top, bottom, width, height } = chartArea
        const { x, y } = scales;
        var gradient = ctx.createLinearGradient(0, top, 0, bottom);
        gradient.addColorStop(0, 'rgba(53, 156, 251, 0.30)');
        gradient.addColorStop(1, 'rgba(53, 156, 251, 0)');
        return gradient
      }

      const analyticsBody = document.querySelector('.analytics__body')
      const totalLabels = analyticsChartLine.data.labels.length
      let oldWidth = analyticsBody.offsetWidth

      if (window.outerWidth < 1500) {
        analyticsBody.style.width = '1000px'
      }

      //Change datasets
      const analyticsItems = document.querySelectorAll('.line__item')

      for (var item of analyticsItems) {
        item.addEventListener('click', function () {
          for (item of analyticsItems) {
            item.classList.remove('active')
          }
          this.classList.toggle('active')
          // analyticsChartLine.data.labels = Object.keys(data)
          // analyticsChartLine.data.datasets[0].data = Object.values(data)
          // analyticsChartLine.options.plugins.legend.labels = Object.values(data)
          // analyticsChartLine.update()

          if (this.dataset.type === 'year') {
            setDays('365');

          } else if (this.dataset.type === 'month') {
            setDays('30');
            // analyticsChartLine.data.labels = Object.keys(data_month)
            // analyticsChartLine.data.datasets[0].data = Object.values(data_month)
            // analyticsChartLine.options.plugins.legend.labels = Object.values(data_month)
            // analyticsChartLine.update()
          } else if (this.dataset.type === 'week') {
            setDays('7');
            // analyticsChartLine.data.labels = Object.keys(data_week)
            // analyticsChartLine.data.datasets[0].data = Object.values(data_week)
            // analyticsChartLine.options.plugins.legend.labels = Object.values(data_week)
            // analyticsChartLine.update()
          }
          const totalLabels = analyticsChartLine.data.labels.length
        })
      }
      return () => {
        analyticsChartLine.destroy();
      }
    }

  }, [data]);

  return <>
    <h2 className="analytics__title">
      DAO Treasury
    </h2>
    <h3 className="analytics__subtitle">
      USD
    </h3>
    <div className="line__nav analytics__nav">
      <div className="line__item analytics__item line__nvigation" data-type="week">Week</div>
      <div className="line__item analytics__item line__nvigation active" data-type="month">Month</div>
      {/* <div className="line__item analytics__item line__nvigation " data-type="year">Year</div> */}
    </div>
    <div className="analytics__container">
      <div className="analytics__body">
        <canvas id="analyticsChartLine" height="290px"></canvas>
      </div>
    </div>
  </>;
}

export default BalanceHistory;