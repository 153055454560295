import { create } from 'ipfs-http-client'
const axios = require('axios');

export const ipfs = create('https://ipfs.rocketmoon.space/ipfs');
export const ipfsHost = 'https://ipfs.rocketmoon.space/';
export const ipfsUrl = 'https://ipfs.rocketmoon.space/ipfs/';

export const getMarketpaceData = async (cid) => {
  const marketplaceData = await getFile(cid);

  return {
    name: marketplaceData.name,
    description: marketplaceData.description,
    avatarUrl: marketplaceData.avatarImg,
    backgroundImg: marketplaceData.backgroundImg,
  };
};

export const getFile = async (cid) => {
  try {
    const response = await axios.get(ipfsUrl + cid);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const prepareFileToIpfs = (file, cb) => {
  const reader = new window.FileReader();
  reader.readAsArrayBuffer(file);
  reader.onloadend = async () => {
    await cb(Buffer(reader.result));
  };
};

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};
