import { useEffect, useState } from "react";
import { getTokenBalance } from "../../../../utils/requestApi/wallet";
import { useParams } from "react-router-dom";

const LineBalance = () => {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    const getWalletTokenBalance = async () => {
      const response = await getTokenBalance({ id });
      console.log('responseee', response);
      setData(response.data.pie);
    };
    getWalletTokenBalance();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const distributionChart = document.querySelector('.distribution__chart')
      const keyList = Object.keys(data)
      let sectionsList = []

      keyList.sort((a, b) => {
        return data[b].percent - data[a].percent
      })


      const percents = {}
      for (var key in data) {
        percents[key] = data[key].percent
      }

      let percent = 0
      for (let i = 0; i < keyList.length; i++) {
        percent += data[keyList[i]].percent * 100
        let section = ''
        if (percent < 95) {
          section = `<div class="distribution__chart-section" style="flex: 0 0 ${data[keyList[i]].percent * 100}%" data-key=${keyList[i]}><span>$${keyList[i]}</span></div>`
        } else if (percent > 95) {
          if (percent < 100) {
            if (data[keyList[i]].percent * 100 > 95) {
              section = `<div class="distribution__chart-section" style="flex: 0 0 95%" data-key=${keyList[i]}><span>$${keyList[i]}</span></div>`
            } else {
              section = `<div class="distribution__chart-section" style="flex: 0 0 5%" data-key="other"></div>`
              sectionsList.push(section)
              break
            }
          } else if (percent > 100) {
            percent -= data[keyList[i]].percent * 100
            section = `<div class="distribution__chart-section" style="flex: 0 0 ${100 - percent}%" data-key="other"></div>`
            sectionsList.push(section)
            break
          } if (percent === 100) {
            section = `<div class="distribution__chart-section" style="flex: 0 0 ${data[keyList[i]].percent * 100}%" data-key=${keyList[i]}><span>$${keyList[i]}</span></div>`
          }
        } else {
          percent -= data[keyList[i]].percent * 100
          section = `<div class="distribution__chart-section" style="flex: 0 0 ${100 - percent}%" data-key="other"></div>`
          sectionsList.push(section)
          break
        }
        if (i == 5) { //Добавил новое условие, если уже добавлено 5 пунктов в график
          percent -= data[keyList[i]].percent * 100
          section = `<div class="distribution__chart-section" style="flex: 0 0 ${100 - percent}%" data-key="other"></div>`
          sectionsList.push(section)
          break
        }
        console.log(percent)
        sectionsList.push(section)
      }
      distributionChart.textContent = ''
      sectionsList.forEach(el => {
        distributionChart.innerHTML += el
      })

      sectionsList = document.querySelectorAll('.distribution__chart-section')
      //Handlers
      let information = document.querySelector('.information')


      let getSiblingsKeys = function (e) {
        // for collecting siblings
        let siblings = [];
        // if no parent, return no sibling
        if (!e.parentNode) {
          return siblings;
        }
        // first child of the parent node
        let sibling = e.parentNode.firstChild;

        // collecting siblings
        while (sibling) {
          if (sibling.nodeType === 1 && sibling !== e) {
            siblings.push(sibling.dataset.key);
          }
          sibling = sibling.nextSibling;
        }
        return siblings;
      };

      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }


      function showInfo(key) {

        let info = `
          <div class="information__content">
              <p class="information__label">$${key} Token</p>
              <p class="information__sublabel">$${data[key].price}</p>
              <img src="${data[key].logo}" alt="" class="information__image">
              <p class="information__percent">${Math.floor(data[key].percent * 100 * 100) / 100}%</p>
              <p class="information__sum">$${numberWithCommas(Math.floor(data[key].value))}</p>
          </div>
          `
        information.innerHTML = info
      }

      function showTable(exclude) {
        let rows = []
        table.tBodies[0].textContent = ''
        for (var key in data) {
          if (!exclude.includes(key)) {
            let tr = `
              <tr class="table__row">
                  <td class="table__column">${key}</td>
                  <td class="table__column">$${numberWithCommas(Math.floor(data[key].value * 100) / 100)}</td>
              </tr>
          `
            table.tBodies[0].innerHTML += tr
          }
        }
        table.hidden = false
      }


      let table = document.querySelector('#chart-table')
      //Events
      sectionsList.forEach(el => {
        el.addEventListener('click', function () {

          if (this.dataset.key != 'other') {
            if (information.classList.contains('_hide')) {
              information.classList.remove('_hide')
            }
            if (table.hidden == false) {
              table.hidden = true
            }
            showInfo(this.dataset.key)
          } else {
            if (!information.classList.contains('_hide')) {
              information.classList.add('_hide')
            }
            showTable(getSiblingsKeys(this))
          }
        })
      })

      showInfo(keyList[0])

      // return () => {
      //   distributionChart.destroy();
      // }
    }

  }, [data])

  return (
    <>
      {data && data.length > 0 && <>
        <h2 class="analytics__title">
          Token Distribution
        </h2>
        <h3 class="analytics__subtitle">
          % of Treasury
        </h3>
        <div class="distribution">
          <div class="distribution__chart">
            <div class="distribution__chart-section" data-key="0"><span></span></div>
            <div class="distribution__chart-section" data-key="1"><span></span></div>
            <div class="distribution__chart-section" data-key="2"></div>
            <div class="distribution__chart-section" data-key="3"></div>
            <div class="distribution__chart-section" data-key="4"></div>
            <div class="distribution__chart-section" data-key="other"></div>
          </div>
          <div class="distribution__info information">
            <div class="information__content">
              <p class="information__label"></p>
              <p class="information__sublabel"></p>
              <img src="img/FOX.png" alt="" class="information__image" />
              <p class="information__percent"></p>
              <p class="information__sum"></p>
            </div>
          </div>
          <table class="table" id="chart-table" hidden >
            <thead class="table__head">
              <tr class="table__row">
                <td class="table__column">Token</td>
                <td class="table__column">Value</td>
              </tr>
            </thead>
            <tbody class="table__body">

            </tbody>
          </table>
        </div>
      </>}

    </>
  )
}

export default LineBalance;