import Header from './components/Header/index';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/index';
import SignIn from './pages/Login/index';
import { HOME, LOGIN, CREATE_WALLET, WALLETS, WALLET, PROFILE, ADMIN_WALLETS } from './utils/routings';
import CreateWallet from './pages/Wallets/create';
import Wallets from './pages/Wallets/index';
import WalletInfo from './pages/WalletInfo/index';
import './assets/css/normalize.css';
import './assets/css/icomoon.css';
import Profile from './pages/Profile';
import AdminWallets from './pages/AdminWallets';
import VerticalListAnimation from './components/VerticalTextV2';

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path={HOME} element={<Home />} />
        <Route exact path={LOGIN} element={<SignIn />} />
        <Route exact path={CREATE_WALLET} element={<CreateWallet />} />
        <Route exact path={WALLETS} element={<Wallets />} />
        <Route path={WALLET}>
          <Route path=":id" element={<WalletInfo />} />
          <Route path="update/:id" element={<CreateWallet />} />
        </Route>
        <Route path={PROFILE}>
          <Route path=":address" element={<Profile />} />
        </Route>
        <Route exact path={ADMIN_WALLETS} element={<AdminWallets />} />
      </Routes>
      <VerticalListAnimation />
    </>
  );
};

export default App;
