import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Flex } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { addWalletCategory, deleteWalletCategory, getWalletCategories, updateWalletCategory } from "../../../utils/requestApi/wallet";
import ActionCateforyForm from "./actionCategoryModal";
import AddWalletCateforyForm from "./actionCategoryModal";
import DeleteModal from "./deleteCategoryModal";

function Categories() {
  const [categories, setCategories] = useState();

  const getCategories = async () => {
    const response = await getWalletCategories();
    setCategories(response.data)
    console.log('res', response.data);
  }

  useEffect(() => {
    getCategories();
  }, []);

  return <Box width={"80%"} margin={"0 auto"}>
    <Flex flexFlow={"row"} justifyContent="space-between" alignContent={"center"} alignItems="center" pb={4}>
      <Box fontSize={"24px"}>Categories</Box>
      <ActionCateforyForm updateCategoryList={getCategories} />
    </Flex>

    <table className="table">
      <thead className="table__head">
        <tr className="table__row">
          <td className="table__column">Category Name</td>
          <td className="table__column">Action</td>
        </tr>
      </thead>
      <tbody className="table__body">
        {categories &&
          categories.map((item, index) => {
            return (
              <>
                <tr className="table__row" >
                  <td className="table__column"> {item.name}</td>
                  <td className="table__column"> <ActionCateforyForm id={1} name={item.name} updateCategoryList={getCategories} /> <DeleteModal id={item.id} updateCategoryList={getCategories} /> </td>
                </tr>
              </>
            );
          })}
      </tbody>
    </table>
  </Box>;
}

export default Categories;