import './VerticalListAnimation.css'; // Import your CSS file

const VerticalListAnimation = () => {

  return (

    <>
        <div class="viewport-scroll-text-wrapper-vertical left" >
            <ul class="viewport-scroll-text-list-vertical left ">
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                        <div class="text-block">DESCI</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">DEVELOPER</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">GUILD</div>
                    </div>
                </li>
            </ul>
            <ul class="viewport-scroll-text-list-vertical left">
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">DESCI</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">DEVELOPER</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">GUILD</div>
                    </div>
                </li>

            </ul>
        </div>
        <div class="viewport-scroll-text-wrapper-vertical right" >
            <ul class="viewport-scroll-text-list-vertical left ">
            <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                        <div class="text-block">DESCI</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">DEVELOPER</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">GUILD</div>
                    </div>
                </li>
            </ul>
            <ul class="viewport-scroll-text-list-vertical left">
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">DESCI</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">DEVELOPER</div>
                    </div>
                </li>
                <li class="list-item viewport-scroll-text-list-item-vertical left">
                    <div class="viewport-scroll-text-list-item-text-wrapper left">
                    <div class="text-block">GUILD</div>
                    </div>
                </li>

            </ul>
        </div>
    </>
  );
};

export default VerticalListAnimation;
