import { Box } from '@chakra-ui/layout';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { CREATE_WALLET, WALLETS } from '../../utils/routings';
import GnosisTransaction from '../Wallets/gnosis';

import Spline from '@splinetool/react-spline';
import './style.css';



function Home() {
  const { active } = useWeb3React();

  useEffect(() => {
    //расчет ширины для текста
    if (window.screen.width > 780) {
      var title = document.querySelector('.main__title');
      title.style.width = (window.screen.width * 46) / 100 + 'px';
    }
  }, []);


  // useEffect(() => {
  //   const tickerWrappers = document.querySelectorAll('.viewport-scroll-text-wrapper-vertical');

  //   tickerWrappers.forEach((tickerWrapper, ix) => {
  //     const list = tickerWrapper.querySelector('ul.viewport-scroll-text-list-vertical');
  //     const clonedList = list.cloneNode(true);
  //     let listHeight = 10;

  //     list.querySelectorAll('li').forEach((item) => {
  //       listHeight += item.offsetHeight;
  //     });

  //     list.style.height = listHeight + 'px';
  //     clonedList.classList.add('cloned');
  //     tickerWrapper.appendChild(clonedList);

  //     let mod = 1;
  //     if (ix === 1) {
  //       mod = -1;
  //     } else if (ix === 2) {
  //       mod = 1.7;
  //     }
  //     listHeight = mod > 0 ? listHeight : -listHeight;

  //     // gsap
  //     const timeline = gsap.timeline({ repeat: -1 });
  //     const time = 1;

  //     timeline
  //       .fromTo(
  //         list,
  //         { rotation: 0.01, y: 0 },
  //         { force3D: true, y: -listHeight, ease: Power2.easeNone },
  //         0
  //       )
  //       .fromTo(
  //         clonedList,
  //         { rotation: 0.01, y: listHeight },
  //         { force3D: true, y: 0, ease: Power2.easeNone },
  //         0
  //       )
  //       .set(list, { force3D: true, rotation: 0.01, y: listHeight })
  //       .to(
  //         clonedList,
  //         { force3D: true, rotation: 0.01, y: -listHeight, ease: Power2.easeNone },
  //         time
  //       )
  //       .to(
  //         list,
  //         { force3D: true, rotation: 0.01, y: 0, ease: Power2.easeNone },
  //         time
  //       );
  //   });
  // }, []);


  return (
    <main className="main">
      <div className="container home-page_container">
        <div className="main__body">
          <h1 className="main__title">
            <span className="main__title-white banner_title_custom">DeSci DAO</span> <br />
            <span className="main__title-white banner_title_custom">Explorer</span> <br />
            {/* <span className="main__title-white">YOUR DATA</span> */}
            {/* <span className="main__title-white">Data Privacy</span> */}
          </h1>
          <h3 className="main__title-white banner_title_h3">
            A Decentralized DAO Explorer and analytics platform
          </h3>
          {/* <h3 className="main__subtitle">
            <span className="main__subtitle-logo">Stored on Archon.Network</span>
          </h3> */}
          <div className="main__btns">
            <a href={WALLETS} className="main__btn border-around">
              Explore Wallets
            </a>

            {active &&
              <a href={CREATE_WALLET} className="main__btn icon-brains">
                Add DAO{' '}
              </a>
            }

            {/* <Box>
              <GnosisTransaction />
            </Box> */}

          </div>
        </div>
        <div className="main__canvas">
          <Spline scene="https://prod.spline.design/JX46xT1vaTmPnvfG/scene.splinecode" />
        </div>
      </div>
    </main>
  );
}

export default Home;
