import { Button } from '@chakra-ui/react';
import Safe, { SafeFactory } from '@gnosis.pm/safe-core-sdk';
import {
  getProxyFactoryDeployment,
  getMultiSendDeployment,
  getSafeSingletonDeployment
} from '@gnosis.pm/safe-deployments';
import EthersAdapter from '@gnosis.pm/safe-ethers-lib';
import { useWeb3React } from '@web3-react/core';
import { Contract, ethers } from 'ethers';
import Web3 from 'web3';

const contractAddress = '0xA1C2b3504211abBCc4A6E2F9215f5e7690369017';
const contractABI = [
  {
    "inputs": [],
    "name": "get",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "x",
        "type": "uint256"
      }
    ],
    "name": "set",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

// https://gnosis.backend.hashlab.online/cgw/v2/chains/5/safes/0xDf07c4Cd949a244d41FAB204BCaE21D748D86c9A/multisig-transactions/estimations

function GnosisTransaction() {
  const { chainId, account, active } = useWeb3React();
  const handleSendTransaction = async () => {
    console.log('handleSendTransaction');
    const filter = { network: chainId.toString() };
    const safeSingleton = getSafeSingletonDeployment(filter);
    const multiSendLib = getMultiSendDeployment(filter);
    const proxyFactory = getProxyFactoryDeployment(filter);

    const contractNetworks = {
      [chainId]: {
        multiSendAddress: multiSendLib.defaultAddress,
        safeMasterCopyAddress: safeSingleton.defaultAddress,
        safeProxyFactoryAddress: proxyFactory.defaultAddress,
      }
    };
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    // const provider = new ethers.providers.JsonRpcProvider();
    const connectedAccountSigner = provider.getSigner();

    const ethAdapter = new EthersAdapter({ ethers, signer: connectedAccountSigner });

    const safe = await Safe.create({
      ethAdapter: ethAdapter,
      safeAddress: '0xf669549cEEBB9b56ed511C180e724758930Fa3CF',
      contractNetworks: contractNetworks
    });
    const method = 'set';



    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress)
    // const contract = new web3.eth.Contract([method], contractAddress)
    // const { inputs, name = '', signatureHash } = method
    // const args = inputs?.map(extractMethodArgs(signatureHash, values)) || []



    const txObject = contract.methods['set']([100]);
    const data = txObject.encodeABI()
    console.log('data txObject', data);

    const transaction = {
      to: contractAddress,
      data: data,
      value: 0,
      safeTxGas: "38306",
      nonce: 1,
      operation: 0,
      gasPrice: "0",
      operation: 0,
    };

    const options = {
      gasPrice: "0",
      gasToken: "0x0000000000000000000000000000000000000000",
      // nonce: 2,
      operation: 0,
      refundReceiver: "0x0000000000000000000000000000000000000000",
    }

    // const contract = new ethers.Contract(contractAddress, contractABI, connectedAccountSigner);


    const safeTransaction = await safe.createTransaction(transaction);
    console.log('safeTransaction', safeTransaction);

    const hash = await safe.getTransactionHash(safeTransaction);
    console.log('hash', hash);


    const txResponse = await safe.executeTransaction(safeTransaction);
    await txResponse.transactionResponse?.wait();

    console.log('txResponse', txResponse);


    // const txResponse = await safe.approveTransactionHash(hash);
    // await txResponse.transactionResponse?.wait();
    // console.log('txResponse', txResponse);

    // const txResponse_cto = await safeSdk_cto.approveTransactionHash(hashCTO);
    // await txResponse_cto.transactionResponse?.wait();



  }

  return <><Button onClick={handleSendTransaction}>Send</Button></>;
}

export default GnosisTransaction;