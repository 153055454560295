import { Box, Button, Flex, Image, Select } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseImg } from '../../assets/icons/close.svg';
import CustomTextarea from '../../ui-kit/CustomField/Textarea';
import Error from '../../ui-kit/Error/index';
import CustomInput from '../../ui-kit/Input/index';

import WalletSchema from './validation';
import { createWallet, getWallet, updateWallet } from '../../utils/requestApi/wallet';
import { useNavigate, useParams } from 'react-router-dom';
import { WALLET } from '../../utils/routings';
import CustomFileInput from '../../ui-kit/CustomField/FileInput/index';
import { b64toBlob } from '../../utils/helper';
import './style.css';
import GradientLine from '../../components/GradientLine';
import CreateDaoWallerForm from './CreateDaoWallet/createDaoWalletForm';
import CropAvatarImage from './CropAvatarImage';
import MyCustomSelect from '../../ui-kit/CustomField/CustomSelect';
import InfoModal from './InfoModal';
import SelectCategory from './categories';
import { authProfile } from '../../utils/requestApi/profile';

const prioritySelectOptions = [
  { value: 'LOWEST', label: 'Lowest' },
  { value: 'LOW', label: 'Low' },
  { value: 'MIDDLE', label: 'Middle' },
  { value: 'HIGH', label: 'High' },
  { value: 'HIGHEST', label: 'Highest' },
];

const CreateWallet = () => {
  const { id } = useParams();
  const isAddMode = !id;

  let navigate = useNavigate();
  const [, setError] = useState();
  const [updatedImage, setUpdatedImage] = useState(false);
  const [formData, setFormData] = useState('');
  const [isBase64Image, setIsBase64Image] = useState(false);

  const submit = async () => {
    const { address, name, description, projectUrl, twitter, avatar, priority, forumUrl, category } = formData.values;

    const data = new FormData();
    if (!isBase64Image) {
      data.append('avatar', avatar[0]);
    }

    data.append('address', address);
    data.append('name', name);
    data.append('description', description);
    data.append('projects', projectUrl);
    data.append('projects', twitter);
    data.append('projects', forumUrl);
    data.append('priority', priority);
    data.append('category', category);

    const actionMethod = !isAddMode ? updateWallet : createWallet;

    if (!isAddMode) {
      console.log('here');
      await authProfile({ userAddress: address })
    }

    const response = await actionMethod({
      data,
      id,
      setError: (e) => {
        setError(e);
        formData.actions.setSubmitting(false);
        // console.log('e.response', e.response.data.error_message);
        if (e.response.status === 400) {
          formData.actions.setFieldError('address', 'Wallet already exists');
        }
      }
    });

    navigate(`${WALLET}/${isAddMode ? response.data.id : id}`);
  };

  useEffect(() => {
    if (formData !== '') submit();
  }, [formData]);

  const initialValues = {
    address: '',
    name: '',
    avatar: '',
    avatar_type: null,
    description: '',
    projectUrl: '',
    twitter: '',
    forumUrl: '',
    priority: '',
    category: null
  };

  const [formValues, setFormValues] = useState(initialValues);

  const onSubmitHandler = React.useCallback((values, actions) => {
    console.log('values', values);
    setFormData({ values, actions });
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      const getWalletList = async () => {
        const response = await getWallet({ id });
        const { address, avatar, avatar_type, description, name, projects, priority, category } = response.data;
        const data = {
          address,
          name,
          avatar: [avatar],
          avatar_type,
          description,
          projectUrl: projects[0],
          forumUrl: projects[2],
          priority,
          category: category.id,
          ...(projects[1] != '' && { twitter: projects[1], }),
          // avatar && avatar_type ? [`data:${avatar_type};base64,${avatar}`] : ''
        }
        setFormValues(data);
        setIsBase64Image(true);
      };
      getWalletList();

      // get user and set form fields
      // userService.getById(id).then((user) => {
      //   const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
      //   fields.forEach((field) => setValue(field, user[field]));
      //   setUser(user);
      // });
    }
  }, []);

  return (
    <>

      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={WalletSchema}
        validateOnChange={true}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          onChange
        }) => (
          <>
            {/* <Container pt={40} pb="100px"> */}
            <form onSubmit={handleSubmit} className="form">
              <div className="form__body">
                <h2 className="form__title">{isAddMode ? 'New Privacy DAO' : 'Update DAO'}</h2>
                {isAddMode && <InfoModal />}
                <Field
                  id="address"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="address"
                  value={values.address}
                  autoComplete="off"
                  label="Address"
                  // placeholder="Address"
                  description={isAddMode && <Box color="white">I don't have multisig. <CreateDaoWallerForm setFieldValue={setFieldValue} fieldName={"address"} /></Box>}
                />

                {errors.address && touched.address && <Error>{errors.address}</Error>}

                {!values.avatar[0] ? (
                  <>
                    <Field
                      id="avatar"
                      name="avatar"
                      component={CustomFileInput}
                      type="text"
                      accept=".png, .jpg, .jpeg"
                      label="Upload avatar"
                      labelTypes="PNG, JPG, JPEG (Max 10mb)"
                      actionText="Click to upload avatar"
                      fileSizePreferer="Preferred image size: 1280x280px"
                      maxFiles="1"
                      onChange={() => {
                        setIsBase64Image(false);
                      }}
                    />
                    {errors.avatar && touched.avatar && <Error>{errors.avatar}</Error>}
                  </>
                ) : (
                  <>
                    <Box maxW="410px">
                      <CropAvatarImage
                        values={values}
                        setFieldValue={setFieldValue}
                        error={errors.avatar}
                        touched={touched.avatar}
                        image={!isBase64Image
                          ? values.avatar[0]
                          : b64toBlob(values.avatar[0], values.avatar_type)}
                        fieldName='avatar'
                        isAddMode={isAddMode}
                        setIsBase64Image={setIsBase64Image}
                      ></CropAvatarImage>
                    </Box>

                    {/* <Box>
                      <label htmlFor="" className="form__label">
                        Upload avatar
                      </label>

                      <Flex w="full" h="full" pb={6} flexDirection="column" alignContent="center">
                        <Box alignSelf="center" pb={2} maxW="410px">
                          <Image

                            src={
                              !isBase64Image
                                ? URL.createObjectURL(values.avatar[0])
                                : URL.createObjectURL(b64toBlob(values.avatar[0], values.avatar_type))
                            }
                            borderRadius={4}
                          />
                        </Box>

                        <Flex color="gray.900" justifyContent="space-between" alignItems="center">
                          <Box pr={4} color="white">
                            {isAddMode ? values.avatar[0].name : 'avatar'}
                          </Box>
                          <CloseImg onClick={() => setFieldValue('avatar', '')} />
                        </Flex>
                      </Flex>
                    </Box> */}
                  </>
                )}

                <Field
                  id="name"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="name"
                  value={values.name}
                  autoComplete="off"
                  label="Name"
                // placeholder="Name"
                />
                {errors.name && touched.name && <Error>{errors.name}</Error>}

                <Field
                  pt={4}
                  id="description"
                  component={CustomTextarea}
                  type="text"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  autoComplete="off"
                  label="Description"
                // placeholder="Description"
                // description={'Maximum of 150 characters'}
                />
                {errors.description && touched.description && <Error>{errors.description}</Error>}

                <SelectCategory setFieldValue={setFieldValue} />

                <Field
                  id="projectUrl"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="projectUrl"
                  value={values.projectUrl}
                  autoComplete="off"
                  label="DAO Url"
                // placeholder="Project Url"
                />
                {errors.projectUrl && touched.projectUrl && <Error>{errors.projectUrl}</Error>}

                <Field
                  id="twitter"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="twitter"
                  value={values.twitter}
                  autoComplete="off"
                  label="Twitter"
                // placeholder="Twitter"
                />
                {errors.twitter && touched.twitter && <Error>{errors.twitter}</Error>}

                <Field
                  id="forumUrl"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="forumUrl"
                  value={values.forumUrl}
                  autoComplete="off"
                  label="Forum Url"
                // placeholder="Forum Url"
                />
                {errors.forumUrl && touched.forumUrl && <Error>{errors.forumUrl}</Error>}

                {localStorage.getItem('jwtToken') && (isAddMode || values.priority) && (
                  <>
                    <label htmlFor="" className="form__label">
                      Priority
                    </label>

                    <MyCustomSelect onChange={(e) => { setFieldValue('priority', e.value); console.log(e.value) }} options={prioritySelectOptions} defaultValue={prioritySelectOptions[0]} />

                    {/* <Select
                      color={'white'}
                      onChange={(e) => setFieldValue('priority', e.target.value)}
                      defaultValue={values.priority}
                    >
                      <option value="LOWEST">Lowest</option>
                      <option value="LOW">Low</option>
                      <option value="MIDDLE">Middle</option>
                      <option value="HIGH">High</option>
                      <option value="HIGHEST">Highest</option>
                    </Select> */}
                  </>
                )}

                <>
                  <Field
                    id="document"
                    name="document"
                    component={CustomFileInput}
                    type="text"
                    // accept=".png, .jpg, .jpeg"
                    label="Upload document (optional)"
                    labelTypes="PDF, TXT, DOCX, CSV, XLSX, HTML, XML"
                    actionText="Document upload coming in v0.8.1..."
                    // fileSizePreferer="Preferred image size: 1280x280px"
                    maxFiles="1"
                    disabled="disabled"
                  // onChange={() => {
                  // setIsBase64Image(false);
                  // }}
                  />
                  {errors.avatar && touched.avatar && <Error>{errors.avatar}</Error>}
                </>

                <Button
                  height={'40px'}
                  id="login"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                  _hover={{ bg: "rgba(53, 156, 251, 0.75)" }}
                  display="flex"
                  align-items="center"
                  justify-content=" center"
                  background="#359cfb"
                  color="#FFFFFF"
                  margin-top="60px"
                  borderRadius="100px;"
                  className="form__button icon-rorschach"
                >
                  {isAddMode ? 'Add' : 'Update'} DAO
                </Button>

              </div>
            </form>
            {/* </Container> */}
          </>
        )}
      </Formik>

      {/* <p className="bottom_to_top vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
      {/* <p className="top_to_bottom vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
      <GradientLine />
    </>
  );
};

export default CreateWallet;
