import { useEffect } from 'react';

function GradientLine() {
  useEffect(() => {
    //Расчет высоты экрана и вставка вертикального текста на всю высоту
    //растягивает длину градиента
    var right_vertical_text = document.querySelector('.top_to_bottom');
    var gradient_rectangle = document.querySelector('.gradient_rectangle_line');
    gradient_rectangle.style.height = window.innerWidth + 'px';

  }, []);

  return (
    <>
      <div className="gradient_rectangle_line"></div>
    </>
  );
}

export default GradientLine;
