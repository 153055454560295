import axios from 'axios';
import { ethers } from 'ethers';
import { BACKEND_BASE_URL } from '../constants';

export const authProfile = async ({ userAddress }) => {
  const token = localStorage.getItem('authToken');
  if (token && token !== undefined) {
    return localStorage.getItem('authToken');
  } else {
    if (window.ethereum) {
      // const timestamp = moment().unix();
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const connectedAccountSigner = provider.getSigner();

      const messagePattern = `I am signing this message in the Desci app for wallet verification`;
      const signature = await connectedAccountSigner.signMessage(messagePattern, userAddress);

      // const data = new FormData();
      // data.append('signature', signature);
      // data.append('timestamp', timestamp);
      // data.append('address', userAddress);


      // const responce = await getAuthTokenProfile(data);
      localStorage.setItem('authToken', signature);
      // action({token: responce.data.token});
      return signature;
    }
  }
};

export const getUserProfile = async ({ address }) => {
  const responce = await axios.get(
    `${BACKEND_BASE_URL}/users?address=${address}`
  );

  return responce.data;
}