import axios from 'axios';
import { BACKEND_BASE_URL } from '../constants';
import { authProfile } from './profile';

const token = localStorage.getItem('jwtToken');
const config = {
  headers: {
    jwt: token,
  },
};

export const createWallet = async ({ data, setError }) => {
  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/wallets`, data, config);
    return response.data;
  } catch (e) {
    setError(e);
  }
};

export const getWallet = async ({ id, userAddress }) => {
  let paramsString = '';
  if (userAddress != undefined) {
    paramsString = `?user_address=${userAddress}`;
  }
  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}${paramsString}`);
  return responce.data;
};

export const getWallets = async ({ unapprovedOnly, sort, userAddress, category }) => {
  let paramsString = '';

  if (sort) {
    const sortParams = sort.split(' ');
    paramsString = sortParams && sortParams !== 'ALL' ? `&${sortParams[0]}=${sortParams[1]}` : '';
  }

  if (category) {
    paramsString = paramsString + `&category_id=${category}`
  }

  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets?unapproved_only=${unapprovedOnly}${paramsString}&user_address=${userAddress}`, {
    headers: {
      jwt: localStorage.getItem('jwtToken')
    }
  });
  return responce.data;
};

export const getUserWallets = async ({ address }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/users/${address}/wallets`);
  return responce.data;
};

export const getUserFavoriteWallets = async ({ address }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/users/${address}/likedWallets`);
  return responce.data;
};


export const getLeaderboard = async ({ id }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}/top-contributors`);
  return responce.data;
};

export const deleteWallet = async ({ id }) => {
  const responce = await axios.delete(`${BACKEND_BASE_URL}/wallets/${id}`, config);
  return responce.data;
};

export const approveWallet = async ({ id }) => {
  const responce = await axios.put(`${BACKEND_BASE_URL}/wallets/${id}/approve`, {}, config);
  return responce.data;
};

export const declineWallet = async ({ id }) => {
  const responce = await axios.put(`${BACKEND_BASE_URL}/wallets/${id}/dismiss`, {}, config);
  return responce.data;
};

export const updateWallet = async ({ id, data }) => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Sign: localStorage.getItem('authToken'),
    },
  };

  const responce = await axios.put(`${BACKEND_BASE_URL}/wallets/${id}`, data, config);
  return responce.data;
};

export const getNftByWallet = async ({ address }) => {
  const responce = await axios.get(`https://api.opensea.io/api/v1/assets?owner=${address}`);
  return responce.data;
};

export const getTransactionHistory = async ({ id, period }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}/activity?period=${period || 'm'}`);
  return responce.data;
};

export const getTransactionsAmountGraph = async ({ id, type, period }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}/transfers-graph?period=${period}&type=${type}`);
  return responce.data;
};

export const getBalanceHistory = async ({ id, days }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}/balance-history?days=${days || 30}`);
  return responce.data;
};

export const getTokenBalance = async ({ id, page }) => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}/balance-pie${page ? '?page-number=' + page : ''}`);
  return responce.data;
};

export const setLike = async ({ id, address }) => {
  const authToken = await authProfile({ userAddress: address });

  const config = {
    headers: {
      Sign: authToken,
    },
  };

  return await axios.post(`${BACKEND_BASE_URL}/likeWallet?id=${id}`, {}, config);
};

export const getWalletCategories = async () => {
  const responce = await axios.get(`${BACKEND_BASE_URL}/categories`);
  return responce.data;
};

export const addWalletCategory = async (data) => {
  const config = {
    headers: {
      jwt: localStorage.getItem('jwtToken')
    }
  };

  const responce = await axios.post(`${BACKEND_BASE_URL}/categories`, data, config);
  return responce.data;
};

export const updateWalletCategory = async ({ id, name }) => {
  const config = {
    headers: {
      jwt: localStorage.getItem('jwtToken')
    }
  };

  const responce = await axios.put(`${BACKEND_BASE_URL}/categories/${id}`, { name }, config);
  return responce.data;
};

export const deleteWalletCategory = async (id) => {
  const config = {
    headers: {
      jwt: localStorage.getItem('jwtToken')
    }
  };

  const responce = await axios.delete(`${BACKEND_BASE_URL}/categories/${id}`, config);
  return responce.data;
};
