import { useEffect, useState } from 'react';
import './style.css';
import { getWalletCategories, getWallets } from '../../utils/requestApi/wallet';
import { useNavigate } from 'react-router-dom';
import { LOGIN, WALLET } from '../../utils/routings';
import Avatar from '../../assets/images/eth-avatar.png';
import TwitterImg from '../../assets/img/twitter.png';
import ProjectImg from '../../assets/img/project.png';
import ForumImg from '../../assets/img/forum.png';
import MyCustomSelect from '../../ui-kit/CustomField/CustomSelect';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import {
  Menu,
  chakra,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Image,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons';
import SortSrc from '../../assets/icons/sorting_white.png';
import Loading from '../../components/Loader';
import { useWeb3React } from '@web3-react/core';
import LikeIcon from '../WalletInfo/like';
import SelectCategory from './categories';

const selectOptions = [
  { value: 'desc', label: 'desc' },
  { value: 'asc', label: 'asc' },
];

function Wallets({ unapprovedOnly, isAdmin = false }) {
  let navigate = useNavigate();
  const [wallets, setWallets] = useState();
  const [sort, setSort] = useState();
  const [categories, setCategories] = useState();
  const [filterCategoty, setFilterCategoty] = useState();
  const { account, chainId, active } = useWeb3React();


  const getCategories = async () => {
    const response = await getWalletCategories();
    const selectOptions = [];

    response.data.map((item) => {
      selectOptions.push({ value: item.id, label: item.name });
    });

    setCategories(selectOptions);
  }

  useEffect(() => {
    getWalletList({ category: filterCategoty });
  }, [filterCategoty]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  const getWalletList = async ({ category }) => {
    try {
      const response = await getWallets({ unapprovedOnly: unapprovedOnly || false, sort, userAddress: account, category });
      setWallets(response.data);
    } catch (e) {
      console.log(e);
      if (e.response.status === 401) {
        localStorage.removeItem('jwtToken');
        navigate(LOGIN);
      }
    }
  };

  useEffect(() => {
    getWalletList();
  }, [sort, account]);

  useEffect(() => {
    //Расчет высоты экрана и вставка вертикального текста на всю высоту
    // if (window.screen.width > 780) {
    //   let right_vertical_text = document.querySelector('.top_to_bottom');
    //   let left_vertical_text = document.querySelector('.bottom_to_top');
    //   let string_to_vertikal_text = document.querySelector('.top_to_bottom').innerHTML.split('&nbsp;');
    //   let window_height = document.body.clientHeight;
    //   let a = 0;
    //   while (right_vertical_text.clientHeight < window_height) {
    //     if (a < string_to_vertikal_text.length) {
    //       right_vertical_text.innerHTML += string_to_vertikal_text[a] + '&nbsp;';
    //       a++;
    //     } else {
    //       a = 0;
    //     }
    //   }
    //   left_vertical_text.innerHTML = right_vertical_text.innerHTML;
    // }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSort = (e) => {
    console.log('sort', e)
    setSort(e);
  };


  return (
    <>
      {wallets ? (
        <div className="wallets">
          <div className="container">
            <Box className="wallets__body" padding={isAdmin ? '0' : "128px 0 63px 0"}>
              {!isAdmin && <Heading fontSize={"33.9px"} textAlign={'center'} fontFamily={"Space Mono"}  color="white"
              //  pt={'50px'}
                mb={'13px'}  pb={'20px'}><chakra.span textDecoration={'underline'}
              // color="#359cfb"
               >Explore</chakra.span>&nbsp;transactions and social connections</Heading>}
              <Flex flexFlow={"column"} alignItems={"center"} pb={8}>
                <Box justifyContent={"center"} minWidth={"340px"}>
                  <MyCustomSelect onChange={(e) => { setFilterCategoty(e.value) }} options={categories} />
                </Box>
              </Flex>
              {!isAdmin && <Box display={"flex"} justifyContent="space-between" alignItems={"center"} mb={"24px"}>

                <h2 className="wallets__title">
                  <span id="wallets__number">{wallets.length}</span> Wallets
                </h2>

                <Menu>
                  <MenuButton as={Button} colorScheme="teal" color="black" backgroundColor={"#9B9B9B"} _hover={{ bg: '#4D4E58' }} _active={{ bg: '#4D4E58' }}>
                    <Flex alignItems="center">
                      <Image w={4} h={4} src={SortSrc} color="black" mr={2}></Image>
                      <Text fontSize={'xm'} display={{ base: 'none', md: 'flex' }} color="white">
                        Sort & Filter
                      </Text>
                    </Flex>
                  </MenuButton>
                  <MenuList minWidth="240px" zIndex="99999">
                    <MenuOptionGroup
                      defaultValue={'ALL'}
                      title="Sort & Filter"
                      type="radio"
                      onChange={(value) => handleSort(value)}
                    >
                      <MenuItemOption value="">Default</MenuItemOption>
                      <MenuItemOption value="tx_sort asc">Last activity: Latest first</MenuItemOption>
                      <MenuItemOption value="tx_sort desc">Last activity: Oldest first  </MenuItemOption>
                      <MenuItemOption value="balance_sort asc">Balance: Greater First</MenuItemOption>
                      <MenuItemOption value="balance_sort desc">Balance: Smaller First  </MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              }



              <div className="wallets__list">
                {wallets.map((item, index) => (
                  <WalletItem data={item} key={item.id} />
                ))}
              </div>

            </Box>
          </div>
        </div>
      ) : <Box pt={'150px'}>
        <Loading />
      </Box>
      }
      {
        !isAdmin && <>
          {/* <p className="bottom_to_top vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
          {/* <p className="top_to_bottom vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
        </>
      }
    </>
  );
}

export default Wallets;

export function WalletItem({ data, showLikeIcon = true }) {
  const { account, active } = useWeb3React();
  return (
    // <div className="wallets__item-wrap">

    
      <a href={`${WALLET}/${data.id}`} className="wallets__item">
         <div className="wallets__item-inner">
          <div className="">
            <Image src={data.avatar && data.avatar_type
              ? `data:${data.avatar_type};base64,` + data.avatar
              : Avatar} alt="" className="wallets__image wallets__item-block" w={"73px"} height={"73px"} minW={73} borderRadius={10} />
            <div className="wallets__item-social">
              {data.projects[0] && <a href={data.projects[0]}><Image src={ProjectImg} w={"18px"} h={"18px"} /></a>}
              {data.projects[1] && <a href={data.projects[1]}><Image src={TwitterImg} w={"18px"} h={"18px"} /></a>}
              {data.projects[2] && <a href={data.projects[2]}><Image src={ForumImg} w={"18px"} h={"18px"} /></a>}
            </div>
          </div>
          <div className="wallets__item-block">
            <p className="wallets__item-title"><chakra.span>{data.name.length > 20 ? data.name.slice(0, 15) + '...' : data.name} </chakra.span>  {active && data.is_liked && <LikeIcon id={data.id} address={account} status={data.is_liked} size="4" />}</p>

            <p className="wallets__item-description">
              {data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
              </p>
            {/* <div className="wallets__item-social">
              {data.projects[0] && <a href={data.projects[0]}><Image src={ProjectImg} w={"20px"} h={"20px"} /></a>}
              {data.projects[1] && <a href={data.projects[1]}><Image src={TwitterImg} w={"20px"} h={"20px"} /></a>}
              {data.projects[2] && <a href={data.projects[2]}><Image src={ForumImg} w={"20px"} h={"20px"} /></a>}
            </div> */}
          </div >
        </div>
      </a >
  );
}
