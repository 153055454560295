import './style.css';
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Box, Heading, Image, Stack, Text, Link, Flex, chakra, useToast, Button } from '@chakra-ui/react';
import { getWallet, setLike } from '../../utils/requestApi/wallet';
import Avatar from '../../assets/images/eth-avatar.png';
import { truncateAddress } from '../../utils/helper';
import ContributionForm from './contribution';
import WalletActions from './WalletActions';
import AccountAssets from './AccountAssets';
import VerticalText from '../../components/VerticalText';
import CopyToClipboard from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import CreateTokenForm from './createToken';
import ChangeTokenPriceForm from './changeTokenPrice';
import { daoFactoryAddress, MULTISIG_BASE_URL, networks, zeroAddress } from '../../utils/constants';
import { ethers } from 'ethers';
import daoFactoryJson from '../../assets/abi/DAOFactory.json'
import daoTokenJson from '../../assets/abi/DAOToken.json'
import { getMultiSigAddresses } from '../../utils/requestApi';
import ExchangeTokenForm from './exchangeToken';
import { StarIcon } from '@chakra-ui/icons';
import LikeIcon from './like';

const projecTitles = ['DAO', 'Twitter', 'Forum'];

function WalletInfo() {
  const { id } = useParams();
  const toast = useToast();
  const { account, chainId, active } = useWeb3React();
  const [totalBalance, setTotalBalance] = useState(0);
  const [walletInfo, setWalletInfo] = useState('');
  const [networkName, setNetworkNane] = useState('No');
  const [tokenAddress, setTokenAddress] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [multiSigAddresses, setMultiSigAddresses] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [totalSupply, setTotalSupply] = useState(null);

  const getWalletInfo = async () => {
    const response = await getWallet({ id, userAddress: account });
    console.log('response.data getWalletInfo :', response.data);
    setWalletInfo(response.data);
  };

  const getTokenAddress = async () => {
    if (walletInfo.address) {

      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const signer = provider.getSigner();
      const daoABI = daoFactoryJson.abi;
      const daoContract = new ethers.Contract(daoFactoryAddress, daoABI, signer);
      const tokenAddress = await daoContract.tokenPerDao(walletInfo.address);
      setTokenAddress(tokenAddress);
      await getTokenPrice(tokenAddress, signer);
    }
  }

  const getTokenPrice = async (tokenAddress, signer) => {

    const tokenABI = daoTokenJson.abi;
    const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
    const tokenPrice = await tokenContract.getPrice();
    const tokenTotalSupply = await tokenContract.totalSupply();

    console.log('tokenTotalSupply', tokenTotalSupply.toString());
    console.log('tokenPrice', ethers.utils.formatEther(tokenPrice));

    setTokenPrice(ethers.utils.formatEther(tokenPrice));
    setTotalSupply(ethers.utils.formatEther(tokenTotalSupply.toString()));
  }

  const getMultiSigAddressesInfo = async () => {
    if (account) {
      try {
        const response = await getMultiSigAddresses({ account });
        const isOwner = response.safes.includes(walletInfo.address);
        setIsOwner(isOwner);
        setMultiSigAddresses(response.safes);

      } catch (e) {
        console.log('error', e);
      }
    }
  }

  useEffect(() => {
    getMultiSigAddressesInfo();
  }, [account]);

  useEffect(() => {
    getTokenAddress();
    getMultiSigAddressesInfo()
  }, [walletInfo.address]);

  useEffect(() => {
    getWalletInfo();
  }, [active]);

  useEffect(() => {
    getNetworkName();
  }, [chainId]);

  const getNetworkName = () => {
    const network = networks.find((x) => x.id === chainId);
    if (network) setNetworkNane(network.name)
  }

  useEffect(() => {
    //установка картинок как background
    let nft_images = document.querySelectorAll('.nft__item-img');
    for (var img of nft_images) {
      img.parentElement.style.background = 'url(' + img.src + ') center no-repeat';
      img.parentElement.style.backgroundSize = 'cover';
      img.remove();
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="longevity">
        <div className="container">
          <div className="longevity__body">
            <div className="longevity__poster">
              <Image
                objectFit="cover"
                boxSize="100%"
                src={
                  walletInfo.avatar && walletInfo.avatar_type
                    ? `data:${walletInfo.avatar_type};base64,` + walletInfo.avatar
                    : Avatar
                }
                alt=""
                // w="330px"
                height="330px"
              // className="longevity__poster-img"
              />
            </div>
            <div className="longevity__row">
              <h2 className="longevity__title"> {walletInfo.name}</h2>

              {active && walletInfo && <LikeIcon id={id} address={walletInfo.address} status={walletInfo.is_liked} />}
              <WalletActions walletInfo={walletInfo} getWalletInfo={getWalletInfo} />
            </div>

            <div className="longevity__text">
              <p className="longevity__paragraph">{walletInfo.description}</p>
            </div>

            <Flex flexFlow={"row"} gridGap={4} alignItems={"center"}>
              <>
                {tokenAddress == zeroAddress && isOwner && <>
                  <CreateTokenForm multisingAddress={walletInfo.address} getTokenAddress={getTokenAddress} />
                </>
                }
                {isOwner && <>
                  <Link href={`${MULTISIG_BASE_URL}/gor:${walletInfo.address}`} isExternal colorScheme="teal" color="white" >
                    Manage wallet
                  </Link>
                </>
                }
              </>

              {/* <Button background={"#359cfb"} colorScheme="teal"
              variant="solid" onClick={() => alert('Comming soon...')}>Subscription payment</Button> */}
              {tokenAddress && tokenAddress != zeroAddress && tokenPrice && <ExchangeTokenForm tokenAddress={tokenAddress} tokenPrice={tokenPrice} />}
              {tokenPrice && tokenAddress && isOwner && <ChangeTokenPriceForm currentPrice={tokenPrice} tokenAddress={tokenAddress} getTokenAddress={getTokenAddress} />}
            </Flex>
            <Flex gridGap={1} pt={8} flexFlow={"column"}>
              {tokenAddress && tokenAddress != zeroAddress && <Text color={"white"}><chakra.span fontWeight={"bold"}>Token: </chakra.span> {tokenAddress}</Text>}
              {tokenPrice && <Text color={"white"}><chakra.span fontWeight={"bold"}>Price:</chakra.span>  {tokenPrice}</Text>}
              {totalSupply && <Text color={"white"}><chakra.span fontWeight={"bold"}>Total Supply:</chakra.span>  {totalSupply}</Text>}
            </Flex>

            <div className="longevity__row">
              <div className="longevity__block longevity__block-left">
                <p className="longevity__block-title _pc">DAO {walletInfo && walletInfo.projects[1] && 'Twitter'} Forum</p>
                <div className="longevity__btns">
                  {walletInfo && walletInfo.projects.length > 0
                    ? walletInfo.projects.map((item, index) => {
                      let icon;
                      switch (index) {
                        case 0: icon = 'icon-rocket'; break;
                        case 1: icon = 'icon-twitter'; break;
                        case 2: icon = 'icon-shell'; break;
                        default: icon = 'icon-hand';
                      }

                      if (item != '') {
                        return (
                          <Fragment key={index}>
                            <p className="longevity__block-title _mob">{projecTitles[index]}</p>

                            <a href={item} className={`longevity__btn ${icon}`} target={'_blank'}>
                              {projecTitles[index]}
                            </a>

                            <br />
                          </Fragment>
                        );
                      }
                    })
                    : ''}
                </div>
              </div>
              <div className="longevity__block longevity__block-right">
                {/* <p className="longevity__block-title">{networkName} EVM</p> */}
                <h3 className="longevity__block-price"><NumberFormat value={parseFloat(totalBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
                <CopyToClipboard
                  text={walletInfo.address}
                  onCopy={() =>
                    toast({
                      position: 'top-right',
                      render: () => (
                        <Box color="white" p={3} bg="green.500" mt={10}>
                          Success!
                        </Box>
                      )
                    })
                  }
                >
                  <chakra.span cursor={'pointer'} className="longevity__block-link">
                    {truncateAddress(walletInfo.address)}
                  </chakra.span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>

      {account && <ContributionForm toAddress={walletInfo.address} getWalletInfo={getWalletInfo} />}

      <AccountAssets setTotalBalance={setTotalBalance} address={walletInfo.address} id={id} walletStatus={walletInfo.status} />

      {/* <VerticalText /> */}
    </>
  );
}

export default WalletInfo;
