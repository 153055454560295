function VerticalText() {
  return (
    <>
      <p className="bottom_to_top vertical__text">
        DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;<span className="green">PrivateAI</span>
        &nbsp;
      </p>
      <p className="top_to_bottom vertical__text">
        DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;<span className="green">PrivateAI</span>
        &nbsp;
      </p>
    </>
  );
}

export default VerticalText;
