import { useEffect } from 'react';
import Leaderboard from './leaderboard';
import Nfts from './nfts';
import Contributions from './contribitions';
import Tokens from './tokens';
import Voting from './voting';
import Graphics from './Graphics';

const AccountAssets = ({ setTotalBalance, address, id, walletStatus }) => {
  useEffect(() => {
    //Переключение по навигации
    let nav_items = document.querySelectorAll('[data-target]');
    let contents = document.querySelectorAll('[data-category]');
    for (var item of nav_items) {
      item.addEventListener('click', function () {
        for (var item of nav_items) {
          item.classList.remove('active');
        }
        for (item of contents) {
          item.hidden = true;
        }
        this.classList.add('active');
        let content = document.querySelector(`[data-category="${this.dataset.target}"]`);
        content.hidden = false;
      });
    }

    //Вывод при наведении на иконку инфо
    let info = document.querySelector('#info');
    var text_info = info.textContent;

    info.addEventListener('mouseover', function () {
      let info_table = document.createElement('div');
      info_table.classList.add('info');
      info_table.textContent = info.textContent;
      info.append(info_table);
    });
    info.addEventListener('mouseout', function () {
      this.innerHTML = `<i>${text_info}</i>`;
    });
  }, []);

  return (
    <>
      <div className="navigation">
        <div className="container">
          <div className="navigation__body">
            <ul className="navigation__items">
              <li className="navigation__item active" data-target="analytics">
                Analytics
              </li>
              <li className="navigation__item " data-target="tokens">
                Tokens
              </li>
              <li className="navigation__item " data-target="nft">
                NFTs
              </li>
              <li className="navigation__item " data-target="contributions">
                Contributions
              </li>
              <li className="navigation__item " data-target="leaderboard">
                Leaderboard{' '}
                <span className="navigation__item-icon" id="info">
                  <i>The leaderboard is a rating of the most active participants of this DAP. The top 3 contributors can receive an NFT as a reward!</i>
                </span>
              </li>
              <li className="navigation__item " data-target="voting">
                Voting
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="content__body">
            <Graphics address={address}></Graphics>
            <Tokens setTotalBalance={setTotalBalance} address={address} walletStatus={walletStatus} />
            <Nfts address={address} />
            <Contributions address={address} />
            <Leaderboard id={id} />
            <Voting id={id} />

          </div>
        </div>
      </div>
    </>
  );
};

export default AccountAssets;
