import { useEffect, useState } from "react";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import moment from "moment";
import 'chartjs-adapter-date-fns';
import { useParams } from "react-router-dom";
import { getBalanceHistory, getTransactionHistory } from "../../../../utils/requestApi/wallet";

Chart.register(ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip);
// Chart.register(LineController, LineElement, PointElement, LinearScale, Title);


const TransactionHistory = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [period, setPeriod] = useState();

  useEffect(() => {
    const getWalletTransactionHistory = async () => {
      const response = await getTransactionHistory({ id, period });
      console.log('response.data', response.data);
      setData(response.data);
    };
    getWalletTransactionHistory();
  }, [period]);


  useEffect(() => {
    if (data) {
      var moveToFunction = CanvasRenderingContext2D.prototype.moveTo;
      CanvasRenderingContext2D.prototype.moveToLocation = {};
      // Переопределение метода moveTo
      CanvasRenderingContext2D.prototype.moveTo = function (x, y) {
        this.moveToLocation.x = x;
        this.moveToLocation.y = y;
        moveToFunction.apply(this, [x, y]);
      };
      CanvasRenderingContext2D.prototype.dashedLineTo = function (x, y, dashedLength) {
        dashedLength = dashedLength === undefined ? 5 : dashedLength;
        var startX = this.moveToLocation.x;
        var startY = this.moveToLocation.y;
        var deltaX = x - startX;
        var deltaY = y - startY;
        var numberDash = Math.floor(Math.sqrt(deltaX * deltaX + deltaY * deltaY) / dashedLength);
        for (var i = 0; i < numberDash; i++) {
          this[i % 2 === 0 ? 'moveTo' : 'lineTo'](startX + (deltaX / numberDash) * i, startY + (deltaY / numberDash) * i); // эквивалентно this.moveTo (x, y) или this.LineTo (x, y)
        }
        this.moveTo(x, y); // Когда пунктирная линия рисуется непрерывно, начальная точка начинается с текущей точки
      };

      CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        this.beginPath();
        this.moveTo(x + r, y);
        this.arcTo(x + w, y, x + w, y + h, r);
        this.arcTo(x + w, y + h, x, y + h, r);
        this.arcTo(x, y + h, x, y, r);
        this.arcTo(x, y, x + w, y, r);
        this.closePath();
        return this;
      }

      Number.prototype.customFixed = function (fixed) {
        var number = this;
        number = String(number);
        var split = number.split('.');
        if (split.length > 1) {
          var left = split[0];
          var right = split[1].substr(0, (!fixed ? 4 : fixed));
          return Number(left + (fixed !== 0 ? '.' + right : ''));
        } else {
          return Number(number);
        }
      }
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //initialization

      const barData = []
      let maxData = 0
      data.forEach((item, index) => {
        let inSumm = 0
        let outSumm = 0

        let inCount = 0
        let outCount = 0
        if (item.incoming != null) {
          item.incoming.forEach(el => {
            inSumm = inSumm + parseInt(el.value)
            inCount = inCount + 1
          })
        }

        if (item.outgoing) {
          item.outgoing.forEach(el => {
            outSumm = outSumm - parseInt(el.value)
            outCount = outCount + 1
          })
        }

        if (Math.abs(outSumm) > maxData) {
          maxData = Math.abs(outSumm)
        }
        if (inSumm > maxData) {
          maxData = inSumm
        }

        barData.push({
          'date': item.date.split('T')[0],
          'incoming': parseFloat(inSumm),
          'outgoing': outSumm,
          'inCount': inCount,
          'outCount': outCount,
        })
      })

      let step = 0

      if (`${Math.abs(maxData)}`.length > 4 && `${Math.abs(maxData)}`.length < 7) {
        step = 50000
      } else if (`${Math.abs(maxData)}`.length > 6) {
        step = 1000000
      }

      //Нахождение крайних чисел на графике
      maxData = Math.ceil(maxData)
      if (`${maxData}`[`${maxData}`.length - 2] + `${maxData}`[`${maxData}`.length - 1] > 50) {
        maxData = 100 - parseInt(`${maxData}`[`${maxData}`.length - 2] + `${maxData}`[`${maxData}`.length - 1]) + maxData
      } else if (0 < `${maxData}`[`${maxData}`.length - 2] + `${maxData}`[`${maxData}`.length - 1] < 50) {
        maxData = 50 - parseInt(`${maxData}`[`${maxData}`.length - 2] + `${maxData}`[`${maxData}`.length - 1]) + maxData
      }

      const canvas = document.getElementById('analyticsChartBar')
      const ctx = canvas.getContext('2d');

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //plugins

      //Dashed line on hover 
      const customLegend = {
        id: 'customLegend',
        beforeDatasetsDraw(chart, args, pluginOptions) {
          const { ctx, data, legend: { legendItems }, chartArea: { top, bottom }, scales: { x, y }, tooltip: { _active } } = chart
          ctx.save()
          if (_active[0]) {
            ctx.beginPath()
            ctx.lineWidth = 1
            ctx.strokeStyle = '#fff'
            ctx.moveTo(_active[0].element.x, top)
            ctx.dashedLineTo(_active[0].element.x, bottom, 2)
            ctx.stroke()
          }
        }
      }

      // first day of month position line
      function getFirstDayId(arr) {
        const result = []
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (element.split(' ')[1] === '1') {
            result.push(index)
          }
        }

        return result
      }

      const firstDay = {
        id: 'firstDay',
        afterDraw(chart, args, pluginOptions) {
          const { ctx, data, chartArea: { top, bottom }, tooltip, scales: { x, y } } = chart
          ctx.save()
          const first_days = getFirstDayId(x._labelItems.map(el => {
            return el.label
          }))

          first_days.forEach((element) => {
            ctx.beginPath()
            ctx.lineWidth = 1
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.4)'
            if (x._gridLineItems[element]) {
              ctx.moveTo(x._gridLineItems[element].tx1, top)
              ctx.lineTo(x._gridLineItems[element].tx1, bottom)
            }
            ctx.stroke()
          });
        }
      }

      //X and Y axes labels settings
      const customScales = {
        id: 'customScales',
        afterDatasetsDraw(chart, args, pluginOptions) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, tooltip: { _active } } = chart
          ctx.save()

          // X axes
          x.ticks.forEach((tick, index) => {

            ctx.font = '600 13px Space Mono'
            ctx.fillStyle = '#99999B'
            ctx.textAlign = 'center'
            ctx.fillText(tick.label.split(' ')[0].toUpperCase(), x.getPixelForValue(tick.value), top - 41)
            if (tick.label.split(' ').length > 1) {
              ctx.fillText(tick.label.split(' ')[1], x.getPixelForValue(tick.value), top - 25)
            }

          })
          if (_active[0]) {
            ctx.font = '600 13px Space Mono'
            ctx.fillStyle = '#fff'
            ctx.textAlign = 'center'
            // const date = new Date(barData[_active[0].index].date).format('mmm d')
            const date = moment(barData[_active[0].index].date).format('mmm d')
            ctx.fillText(date.split(' ')[0].toUpperCase(), _active[0].element.x, top - 41)
            if (date.split(' ').length > 1) {
              ctx.fillText(date.split(' ')[1], _active[0].element.x, top - 25)
            }
          }

          // Y axes
          y.ticks.forEach((tick, index) => {
            ctx.font = '600 13px Space Mono'
            ctx.fillStyle = '#99999B'
            ctx.textAlign = 'end'
            ctx.fillText(tick.label, left - 19, y.getPixelForValue(tick.value))
            ctx.beginPath();
            ctx.fillStyle = '#fff'
            ctx.arc(left - 9, y.getPixelForValue(tick.value), 2, 0, 2 * Math.PI)
            ctx.fill()

          })
        }
      }

      //Custom tooltip
      const customTooltip = {
        id: 'customTooltip',
        afterDraw(chart) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, tooltip: { _active } } = chart
          // x, y, w, h, r
          if (_active[0]) {

            let _width = 255

            let _y1_correction = -50

            let _y2_correction = 30






            let x_rectangle = _active[0].element.x - _width / 2
            let x_left_text = _active[0].element.x - _width / 2 + 36
            let x_right_text = _active[0].element.x - _width / 2 + 86.5
            if (x_rectangle < 0) {
              x_rectangle = 0
              x_left_text = 0 
              // + 36
              x_right_text = 0 + 86.5
            }
            if (x_rectangle + 215 > right) {
              x_rectangle = right - _width
              x_left_text = right - _width 
              // + 36
              x_right_text = right - 215 + 86.5
            }
            let incomeOutput = barData[_active[0].index].incoming
            let outgoingOutput = barData[_active[0].index].outgoing
            const isFirstDataset = analyticsChartBar.isDatasetVisible(0)
            const isSecondDataset = analyticsChartBar.isDatasetVisible(1)

            if (Number.isInteger(incomeOutput)) {
              incomeOutput = incomeOutput + '.00'
            }
            if (Number.isInteger(outgoingOutput)) {
              outgoingOutput = Math.abs(outgoingOutput) + '.00'
            }

            ctx.beginPath()
            ctx.fillStyle = 'rgba(77, 78, 88, 0.4)'
            if (isFirstDataset) {
              ctx.roundRect(x_rectangle, top + 36  + _y1_correction, _width, 48, 8).fill()
            }
            if (isSecondDataset) {
              ctx.roundRect(x_rectangle, bottom - 48 - 36 + _y2_correction, _width, 48, 8).fill()
            }
            ctx.font = '400 14px Space Mono'
            ctx.textAlign = 'start'
            ctx.fillStyle = '#fff'
            if (isFirstDataset) {
              ctx.fillText(`${barData[_active[0].index].inCount} Incoming Transactions:`, x_left_text, top + 40 + 10  + _y1_correction)
            }
            if (isSecondDataset) {
              ctx.fillText(`${barData[_active[0].index].outCount} Outgoing Transactions:`, x_left_text, bottom - 50 - 18 + _y2_correction)
            }

            ctx.font = '700 14px Space Mono'
            ctx.fillStyle = '#359cfb'
            if (isFirstDataset) {
              ctx.fillText(`$${incomeOutput}`, x_right_text, top + 44 + 30  + _y1_correction)
            }
            ctx.font = '700 14px Space Mono'
            ctx.fillStyle = '#74747A'
            if (isSecondDataset) {
              ctx.fillText(`$${outgoingOutput}`, x_right_text, bottom - 48 + _y2_correction)
            }
          }
        }
      }

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //Config
      const analyticsChartBar = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: barData.map(el => {
            return el.date
          }),
          datasets: [{
            label: 'Incoming',
            data: barData.map(el => {
              return el.incoming
            }),
            backgroundColor: [
              '#359cfb'
            ],
            fill: true,
            stack: '0',
            barPercentage: 0.4,

          },
          {
            label: 'Outgoing',
            data: barData.map(el => {
              return el.outgoing
            }),
            backgroundColor: [
              '#3E3E42'
            ],
            fill: true,
            stack: '0',
            barPercentage: 0.4,
          }
          ],
        },
        options: {

          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 10,
              bottom: 10
            }
          },
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {

              onClick(e, item) {

                const legend = item.datasetIndex
                let isFirstData = analyticsChartBar.isDatasetVisible(0)
                let isSecondData = analyticsChartBar.isDatasetVisible(1)
                if (legend === 0) {
                  if (isFirstData) {
                    analyticsChartBar.hide(0)
                  } else {
                    analyticsChartBar.show(0)
                  }
                } else if (legend === 1) {
                  if (isSecondData) {
                    analyticsChartBar.hide(1)
                  } else {
                    analyticsChartBar.show(1)
                  }
                }

                analyticsTypeItem.forEach(el => {
                  el.classList.remove('active')
                })

                isFirstData = analyticsChartBar.isDatasetVisible(0)
                isSecondData = analyticsChartBar.isDatasetVisible(1)
                if (isFirstData && isSecondData) {
                  analyticsTypeItem[2].classList.add('active')
                } else if (!isFirstData && isSecondData) {
                  analyticsTypeItem[1].classList.add('active')
                } else if (isFirstData && !isSecondData) {
                  analyticsTypeItem[0].classList.add('active')
                }

              },
              align: 'end',
              display: true,
              labels: {
                font: {
                  family: "Space Mono",
                  size: 14,
                  weight: 700,
                  height: 16
                },
                color: '#fff',
                padding: 24,
                usePointStyle: true,
                pointStyle: 'rect'
              },
              maxWidth: 16
            },
          },
          scales: {
            x: {
              position: 'top',
              type: 'time',
              time: {
                unit: 'day',
              },
              min: barData[0].date,
              max: barData[barData.length - 1].date,
              ticks: {
                display: true,
                color: 'transparent',
                padding: 40,
                stacked: true,

              },
              grid: {
                color: 'rgba(81, 81, 86, 0.15)',
                borderDash: [2],
                borderDashOffset: 2,
                drawBorder: false,
                offset: false,
              },

            },
            y: {
              min: -maxData,
              max: maxData,
              grace: '50%',
              ticks: {
                // stepSize: 1000 ? `${maxData}`.length>3 && `${maxData}`.length<6: 1000000 ,
                stepSize: step,
                color: 'transparent',
                padding: 8,
                stacked: true,
                //   callback: function(values, ticks, labels){
                //     let value = `${values}`
                //     return value.substr(0, value.length-6) + 'M'
                //   }
                callback: function (values, ticks, labels) {
                  let value = `${Math.abs(values)}`
                  if (value.length > 3 && value.length < 7) {
                    return Math.abs(parseInt(value.substring(0, value.length - 3))) + 'K'
                  } else if (value.length > 6) {
                    return Math.abs(parseInt(value.substring(0, value.length - 6))) + "M"
                  } else {
                    return Math.abs(value)
                  }
                }
              },
              grid: {
                color: 'rgba(81, 81, 86, 0.4)',
                borderDash: [2],
                borderDashOffset: 2,
              },

            }
          },
        },
        plugins: [customLegend, firstDay, customScales, customTooltip],
        // plugins: [customScales, customTooltip],
      });

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //Scripts
      const analyticsTypeItem = document.querySelectorAll('.transactions__item-type')
      const analyticsItems = document.querySelectorAll('.transactions__item-range')
      const analyticsBody = document.querySelectorAll('.analytics__body')
      let transactionItemsArray = []

      getTransactionsList(0)
      setRange('month')

      function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
        // .then(() => {
        //     console.log('Text copied to clipboard');
        // })
        // .catch(err => {
        //     console.error('Error in copying text: ', err);
        // });
      }

      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      function getTransactionsList(index) {
        const transactionItems = []
        var transactionsList = document.querySelector('.transactions')
        transactionsList.textContent = ''

        if (data[index].incoming) {
          data[index].incoming.forEach(el => {
            transactionItems.push({ 'incoming': el })
          })
        }

        if (data[index].outgoing) {
          data[index].outgoing.forEach(el => {
            transactionItems.push({ 'outgoing': el })
          })
        }

        transactionItems.sort((a, b) => {
          if (a.incoming && b.incoming) {
            var date1 = new Date(a.incoming.date)
            var date2 = new Date(b.incoming.date)
          } else if (a.outgoing && b.outgoing) {
            var date1 = new Date(a.outgoing.date)
            var date2 = new Date(b.outgoing.date)
          } else if (a.incoming && b.outgoing) {
            var date1 = new Date(a.incoming.date)
            var date2 = new Date(b.outgoing.date)
          } else if (a.outgoing && b.incoming) {
            var date1 = new Date(a.outgoing.date)
            var date2 = new Date(b.incoming.date)
          }
          return date1 - date2
        })

        function createLiItem(type, date, value, from, to, link, copyData) {

          // let li = document.createElement('li')
          // li.classList.add('analytics__transactions-item', 'transactions__item')
          // let span_arrow = document.createElement('span')
          // let li_content = document.createElement('div')
          // li_content.classList.add('transactions__item-content')
          // span_arrow.classList.add('transactions__arrow')

          if (type === 'incoming') {
            // span_arrow.classList.add('transactions__arrow-down')
            // console.log(li)
            var li = `
                  <li class="analytics__transactions-item transactions__item">
                      <span class="transactions__arrow transactions__arrow-down"></span>
                      <div class="transactions__item-content">
                          <span class="transactions__from transactions__column" data-address=${copyData}><i></i><span class="gray">From: &ThinSpace;</span>  ${from}</span>
                          <span class="transactions__value transactions__column" style="color: #359cfb">USD ${value}</span>
                          <span class="transactions__to transactions__column" data-address=${to}><i></i><span class="gray">To: </span> ${to}</span>
                          <span class="transactions__date transactions__column">${moment(date).format('MMM DD, HH:MM')}</span>
                          <a href="#" class="transactions__link transactions__column"><i></i><span>${link}</span></a>
                      </div>
                  </li>
              `
          } else {
            var li = `
              <li class="analytics__transactions-item transactions__item">
                  <span class="transactions__arrow transactions__arrow-up"></span>
                  <div class="transactions__item-content">
                      <span class="transactions__from transactions__column" data-address=${copyData}><i></i><span class="gray">From: &ThinSpace;</span>  ${from}</span>
                      <span class="transactions__value transactions__column" style="color: #6D6D6D">USD ${value}</span>
                      <span class="transactions__to transactions__column" data-address=${to}><i></i><span class="gray">To: </span> ${to}</span>
                      <span class="transactions__date transactions__column">${moment(date).format('MMM DD, HH:MM')}</span>
                      <a href="#" class="transactions__link"><i></i>${link}</a>
                  </div>
              </li>
          `
          }
          return li

        }

        transactionItems.forEach(el => {

          if (el.incoming) {
            var date = new Date(el.incoming.date)
            var value = numberWithCommas(Math.floor(el.incoming.value * 100) / 100)
            var from = el.incoming.address.split('').map((el, index, a) => {
              if (index < 8 || index > a.length - 8) {
                return el
              } else if (index > 8 && index < 12) {
                return '.'
              }
            }).join('')
            var to = '0x643fds....2234dsxz'
            var link = el.incoming.tx_hash.split('').map((el, index, a) => {
              if (index < 8 || index > a.length - 8) {
                return el
              } else if (index > 8 && index < 12) {
                return '.'
              }
            }).join('')
            var li = createLiItem('incoming', date, value, from, to, link, el.incoming.address)
          } else if (el.outgoing) {
            var date = new Date(el.outgoing.date)
            var value = numberWithCommas(Math.floor(el.outgoing.value * 100) / 100)
            var from = el.outgoing.address.split('').map((el, index, a) => {
              if (index < 8 || index > a.length - 8) {
                return el
              } else if (index > 8 && index < 12) {
                return '.'
              }
            }).join('')
            var to = '0x643fds....2234dsxz'
            var link = el.outgoing.tx_hash.split('').map((el, index, a) => {
              if (index < 8 || index > a.length - 8) {
                return el
              } else if (index > 8 && index < 12) {
                return '.'
              }
            }).join('')
            var li = createLiItem('outgoing', date, value, from, to, link, el.outgoing.address)
          }
          transactionsList.innerHTML += li
        })
      }

      function showTransactions(click) {
        const points = analyticsChartBar.getElementsAtEventForMode(click, 'nearest',
          { intersect: true }, true)

        if (points.length) {
          const firstPoint = points[0]
          getTransactionsList(firstPoint.index)
        }

        //После создания списка транзакций добавляем их в массив и к каждой ссылке на копирование доабвляем соотвествующую функцию
        transactionItemsArray = document.querySelectorAll('.transactions__item [data-address]')
        transactionItemsArray.forEach(el => {
          el.addEventListener('click', function () {
            copyToClipboard(el.dataset['address'])
          })
        })
      }
      canvas.onclick = showTransactions

      const totalLabels = analyticsChartBar.data.labels.length
      let oldWidth = analyticsBody.offsetWidth

      if (window.outerWidth < 1500) {
        analyticsBody.forEach(el => {
          el.style.width = '1000px'

        })
      }

      //Set week,month,year
      function setRange(type) {
        for (let item of analyticsItems) {
          item.classList.remove('active')
        }

        if (type === 'year') {
          analyticsItems[2].classList.add('active')
          // analyticsChartBar.data.labels = Object.keys(data_year)
          // analyticsChartBar.data.datasets[0].data = Object.values(data_year)
          // analyticsChartBar.options.plugins.legend.labels = Object.values(data_year)
        } else if (type === 'month') {
          analyticsItems[1].classList.add('active')
          if (barData.lngth > 31) {
            analyticsChartBar.options.scales.x.min = barData[barData.length - 32].date
            getTransactionsList(barData.length - 32)
          } else {
            analyticsChartBar.options.scales.x.min = barData[0].date
            getTransactionsList(0)
          }
          analyticsChartBar.options.scales.x.max = barData[barData.length - 1].date
        } else if (type === 'week') {
          analyticsItems[0].classList.add('active')
          analyticsChartBar.options.scales.x.min = barData[barData.length - 8].date
          analyticsChartBar.options.scales.x.max = barData[barData.length - 1].date
          getTransactionsList(barData.length - 8)
        }
        analyticsChartBar.update()
      }

      //Change datasets
      for (let item of analyticsItems) {
        item.addEventListener('click', function () {
          this.classList.toggle('active')
          setRange(this.dataset.type)
        })
      }

      //Change positive/negative
      for (let item of analyticsTypeItem) {
        item.addEventListener('click', function () {
          for (item of analyticsTypeItem) {
            item.classList.remove('active')
          }
          this.classList.toggle('active')
          const isFirstChart = analyticsChartBar.isDatasetVisible(0)
          const isSecondChart = analyticsChartBar.isDatasetVisible(1)
          if (this.dataset.type === 'positive') {
            if (isSecondChart === true) {
              analyticsChartBar.hide(1)
            }
            if (isFirstChart === false) {
              analyticsChartBar.show(0)
            }
          } else if (this.dataset.type === 'negative') {
            if (isSecondChart === false) {
              analyticsChartBar.show(1)
            }
            if (isFirstChart === true) {
              analyticsChartBar.hide(0)
            }
          } else if (this.dataset.type === 'all') {
            if (isSecondChart === false) {
              analyticsChartBar.show(1)
            }
            if (isFirstChart === false) {
              analyticsChartBar.show(0)
            }
          }
        })
      }

      return () => {
        analyticsChartBar.destroy();
      }
    }
  }, [data]);

  return <>
    <h2 className="analytics__title">
      Transaction History
    </h2>
    <h3 className="analytics__subtitle">
      USD
    </h3>
    <div className="transactions__nav analytics__nav">
      <div className="transactions__nav analytics__nav-block">
        <div className="transactions__item-range analytics__item line__nvigation" data-type="week">Week</div>
        <div className="transactions__item-range analytics__item line__nvigation active" data-type="month">Month</div>
        {/* <div className="transactions__item-range analytics__item line__nvigation " data-type="year">Year</div> */}
      </div>
      <div className="analytics__nav-block">
        <div className="transactions__item-type analytics__item line__nvigation" data-type="positive">Positive</div>
        <div className="transactions__item-type analytics__item line__nvigation " data-type="negative">Negative</div>
        <div className="transactions__item-type analytics__item line__nvigation active" data-type="all">All</div>
      </div>
    </div>
    <div className="analytics__container">
      <div className="analytics__body">
        <canvas id="analyticsChartBar" height="352"></canvas>
      </div>
    </div>
    <ul className="analytics__transactions analytics__transactions-list transactions">
    </ul>
  </>;
}

export default TransactionHistory;