import { useEffect, useState } from 'react';
import { Box, Image, SimpleGrid, Text } from '@chakra-ui/react';

import NftImg from '../../../assets/img/NFT.png';
import NftImg1 from '../../../assets/img/NFT_1.png';
import NftImg2 from '../../../assets/img/NFT_2.png';
import NftImg3 from '../../../assets/img/NFT_3.png';
import NftImg4 from '../../../assets/img/NFT_4.png';
import NftImg5 from '../../../assets/img/NFT_5.png';
import NftImg6 from '../../../assets/img/NFT_6.png';
import { MORALIS_APP_ID, MORALIS_SERVER_URL } from '../../../utils/constants';

import axios from 'axios';

const Nfts = ({ address }) => {
  const [nfts, setNfts] = useState();
  useEffect(() => {
    const options = { method: 'GET', headers: { Accept: 'application/json', 'X-API-Key': 'cCODPPtS944xqwAkR0g6Zgg3El8GTNX1IfolLEYmBDpnPGynwaV7xGhXux72Xprt' } };
    const getNfts = async () => {
      try {
        const response = await axios(`https://deep-index.moralis.io/api/v2/${address}/nft?chain=eth&format=decimal`, options)
        setNfts(response.data);
      } catch (e) {
        console.log('error', e);
      }
    };

    if (address) getNfts();
  }, [address]);

  const getImageSrc = (metadata) => {
    const src = metadata.image_full_s3 ? metadata.image_full_s3 : metadata.image;
    console.log('src', src);
    if (src && src.indexOf('ipfs://ipfs') === 0) {
      return src.replace('ipfs://ipfs', 'https://ipfs.io/ipfs/');
    } else if (metadata.image_url) {
      return metadata.image_url;
    } else {
    }
    return src;
  };

  return (
    <>
      <div className="nft" data-category="nft" hidden>
        {nfts && nfts.result && <>
          <h3 className="nft__title">{nfts.result.length} NFTs</h3>
          <div className="nft__list">
            {nfts.result.map((item, index) => {
              const metadata = JSON.parse(item.metadata);
              return (
                <div className="nft__item">
                  <Image
                    src={
                      metadata
                        ? getImageSrc(metadata)
                        : 'https://imgholder.ru/300x300/8493a8/adb9ca&text=NFT+&font=kelson'
                    }
                    alt={`Picture of ${item.name}`}
                    roundedTop="lg"
                    roundedBottom="lg"
                    width="100%"
                    objectFit="cover"
                    // height="220px"
                    className="nft__item-img"
                  />
                </div>
              );
            })}
          </div>
        </>
        }

        {!nfts && <div className='no-data_container'>
          <svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="71" cy="71" r="71" fill="rgba(53, 156, 251, 1)" fillOpacity="1"/>
            <path d="M40 42.5H57L63 47.5H98.5C102.918 47.5 106.5 51.0817 106.5 55.5V56.5V65.5H56L43 100H40C36.6863 100 34 97.3137 34 94V48.5C34 45.1863 36.6863 42.5 40 42.5Z" fillOpacity="0" fill="white"/>
            <path d="M106.632 65.2V53.4588C106.632 50.1451 103.946 47.4588 100.632 47.4588H62.6437L59.5311 44.3446C58.0307 42.8434 55.9953 42 53.8728 42H40C36.6863 42 34 44.6863 34 48V93C34 96.866 37.134 100 41 100H42.8659M106.632 65.2H121.6C122.286 65.2 122.768 65.8753 122.546 66.5244L111.992 97.2976C111.438 98.9142 109.917 100 108.208 100H42.8659M106.632 65.2H58.6026C56.9319 65.2 55.4371 66.2385 54.8541 67.8042L42.8659 100" stroke="#ffffff" strokeWidth="2"/>
          </svg>
          No data
        </div>}
      </div>
    </>
  );
};

export default Nfts;
