import { Heading, } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/react';
import { useEffect } from 'react';
import Wallets from '../Wallets';
import Categories from './Categories';
import './style.css';

function AdminWallets() {
  useEffect(() => {
    let nav_items = document.querySelectorAll('[data-target]');
    let contents = document.querySelectorAll('[data-category]');
    for (var item of nav_items) {
      item.addEventListener('click', function () {
        for (var item of nav_items) {
          item.classList.remove('active');
        }
        for (item of contents) {
          item.hidden = true;
        }
        this.classList.add('active');
        let content = document.querySelector(`[data-category="${this.dataset.target}"]`);
        content.hidden = false;
      });
    }
  }, []);

  return (
    <>
      <div className="profile">
        {/* <div className="container"> */}

          <div className="profile__body">
            <Heading fontSize={"50px"} fontFamily={"Space Mono"} justifyContent={"center"} display="flex" color="white" pt={'50px'} pb={'50px'}> Admin page</Heading>

            <div className="navigation">
              <div className="container">
                <div className="navigation__body">
                  <ul className="navigation__items">
                    <li className="navigation__item active" data-target="wallets">
                      Wallets
                    </li>
                    <li className="navigation__item " data-target="categories">
                      Categories
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="admin-wallets" data-category="wallets">
              <Wallets unapprovedOnly={true} isAdmin={true} />
            </div>

            <div className="favorites" data-category="categories" hidden>
              <Categories />

            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default AdminWallets;