import { useState, useEffect } from 'react';
import {
  Stack,
  Button,
  Input,
  useDisclosure,
  Text,
  IconButton,
  FormControl,
  FormErrorMessage,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  chakra,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { DeleteIcon } from '@chakra-ui/icons';
import { SafeFactory } from '@gnosis.pm/safe-core-sdk';
import {
  getProxyFactoryDeployment,
  getMultiSendDeployment,
  getSafeSingletonDeployment
} from '@gnosis.pm/safe-deployments';
import EthersAdapter from '@gnosis.pm/safe-ethers-lib';
import { ethers } from 'ethers';
import axios from 'axios';

const CreateDaoWallerForm = ({ setFieldValue, fieldName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [confirmationCount, setConfirmationCount] = useState(1);
  const [creating, setCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [existingGnosisWallets, setExistingGnosisWallets] = useState([]);
  const [createdWalletAddress, setCreatedWalletAddress] = useState('');
  const { chainId, account, active } = useWeb3React();
  const [wallets, setWallets] = useState(['']);

  useEffect(() => {
    changeOwnerAddress(0, account);
  }, [account, active]);

  useEffect(() => {
    if (active) {
      changeOwnerAddress(0, account);
      axios.get(`https://safe-transaction.gnosis.io/api/v1/owners/${account}/safes/`).then((response) => {
        setExistingGnosisWallets(response.data.safes);
      });
    }
  }, []);

  const addNewOwner = () => {
    setErrorMessage('');
    setCreatedWalletAddress('');
    setWallets((arr) => [...arr, '']);
  };

  const changeOwnerAddressByEvent = (index, e) => {
    changeOwnerAddress(index, e.target.value);
  };

  const changeOwnerAddress = (index, value) => {
    setErrorMessage('');
    setCreatedWalletAddress('');
    const newWallets = [...wallets];
    newWallets[index] = value;
    setWallets(newWallets);
  };

  const deleteOwner = (index) => {
    setErrorMessage('');
    setCreatedWalletAddress('');
    const newWallets = [...wallets];
    newWallets.splice(index, 1);
    setWallets(newWallets);
  };

  const canCreateWallet = () => {
    if (wallets.length === 0) {
      return false;
    }
    for (const address of wallets) {
      if (!address) {
        return false;
      }
    }
    return true;
  };

  const createWallet = async () => {
    try {
      setCreating(true);
      setErrorMessage('');
      const filter = { network: chainId.toString() };
      const safeSingleton = getSafeSingletonDeployment(filter);
      const multiSendLib = getMultiSendDeployment(filter);
      const proxyFactory = getProxyFactoryDeployment(filter);

      const contractNetworks = {
        [chainId]: {
          multiSendAddress: multiSendLib.defaultAddress,
          safeMasterCopyAddress: safeSingleton.defaultAddress,
          safeProxyFactoryAddress: proxyFactory.defaultAddress,
        }
      };
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const connectedAccountSigner = provider.getSigner();

      const connectedAccountEthersAdapter = new EthersAdapter({
        ethers,
        signer: connectedAccountSigner
      });

      const safeFactory = await SafeFactory.create({
        ethAdapter: connectedAccountEthersAdapter,
        contractNetworks: contractNetworks
      });

      const safeAccountConfig = {
        owners: wallets,
        threshold: confirmationCount
      };

      const deployedSafe = await safeFactory.deploySafe({
        safeAccountConfig
      });

      const newSafeAddress = deployedSafe.getAddress();
      console.log('createdWalletAddress', newSafeAddress);
      setFieldValue(fieldName, newSafeAddress);
      setCreatedWalletAddress(newSafeAddress);
      onClose();
    } catch (e) {
      if (e?.message) {
        setErrorMessage(e.message);
      }
    } finally {
      setCreating(false);
    }
  };

  return (
    <>
      <chakra.span onClick={onOpen} textDecoration="underline" cursor={"pointer"}>
        Create
      </chakra.span>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered>
        <ModalOverlay />
        <ModalContent background={"#29292E"}>
          <ModalHeader color={"white"}>Create multisig wallet </ModalHeader>
          <ModalCloseButton color={"white"} />
          <ModalBody color={"white"}>
            {existingGnosisWallets.length > 0 && (
              <Text fontWeight="bold" mb="1rem" color={"white"}>
                Existing multi-signature wallets: {existingGnosisWallets.join('\n')}
              </Text>
            )}
            <FormControl isInvalid={errorMessage}>
              <Stack direction="column" spacing={5}>
                {wallets.map((_, index) => (
                  <Stack direction="row" spacing={3} key={index}>
                    <Input
                      placeholder="Owner Address"
                      size="md"
                      key={`${index}-input`}
                      value={wallets[index]}
                      onChange={(e) => changeOwnerAddressByEvent(index, e)}
                      isInvalid={!wallets[index]}
                    />
                    <IconButton
                      color={"black"}
                      aria-label="delete address"
                      icon={<DeleteIcon />}
                      key={`${index}-trash`}
                      isDisabled={wallets.length === 1}
                      onClick={(_) => deleteOwner(index)}
                    />
                  </Stack>
                ))}
                <Button colorScheme="teal" variant="ghost" color={"#359cfb"} onClick={addNewOwner}>
                  + Add another owner
                </Button>
                <Text mb="8px">Any transaction requires the confirmation of:</Text>
                <NumberInput
                  defaultValue={confirmationCount}
                  min={1}
                  max={wallets.length}
                  onChange={(value) => setConfirmationCount(parseInt(value, 10))}
                  isInvalid={false}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
                {/* {createdWalletAddress && (
                  <Alert
                    status="success"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="200px"
                  >
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Success!</AlertTitle>
                      <AlertDescription>
                        Wallet has been created successfully. Your wallet address is {createdWalletAddress}. Please
                        visit{' '}
                        <Link color="teal.500" href="https://gnosis-safe.io/app/welcome">
                          Gnosis Safe
                        </Link>{' '}
                        to view your wallet
                      </AlertDescription>
                    </Box>
                  </Alert>
                )} */}
              </Stack>
            </FormControl>
          </ModalBody>
          {!createdWalletAddress && (
            <ModalFooter>
              <Button
                colorScheme="teal"
                variant="solid"
                isDisabled={!canCreateWallet()}
                onClick={createWallet}
                isLoading={creating}
                loadingText="Creating"
                background={"#359cfb"}
                borderRadius="20px;"
              >
                Create
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDaoWallerForm;